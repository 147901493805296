import { Link } from "gatsby"
import React from "react"
import Layout from "../../components/Layout"

const Claim = () => {
  return (
    <Layout>
      <div className="claim__picture" />
      <section className="claimants__detail">
        <h1 className="claimants__detail-title">Making a Refugee Claim</h1>
        <p>
          Everyone has the right to asylum. Canada has signed international
          treaties promising to uphold the rights of people seeking refugee
          protection.. A person seeking refugee protection in Canada is called a
          refugee claimant. A refugee claimant is someone who has fled their
          home country and is asking Canada for refugee protection because they
          fear risk of persecution due to their race, religion, nationality,
          political opinion, or membership in a particular social group. Refugee
          claimants have legal, temporary resident status in Canada.
        </p>
        <p>
          The difference between resettled refugees (those who are being
          sponsored) and refugee claimants is that resettled refugees are
          determined by the Canadian government to be refugees <u>before</u>{" "}
          they come to Canada, while refugee claimants go through the process
          after they arrive here. As soon as resettled refugees arrive in
          Canada, they are granted permanent resident status while refugee
          claimants must wait for a decision on whether they are refugees after
          they have arrived. This process can take up to 2 years or more.
        </p>
        <p>
          Individuals can make a refugee claim in Canada at a port of entry upon
          arrival or online through the Canadian Refugee Protection Portal if
          already in Canada. Canada Border Services Agency (CBSA) or
          Immigration, Refugees and Citizenship Canada (IRCC) officials will
          then determine if an individual is{" "}
          <a
            href="https://www.cic.gc.ca/english/helpcentre/answer.asp?qnum=606&top=11"
            target="_blank"
            rel="noreferrer"
          >
            eligible to be referred
          </a>{" "}
          to the Immigration and Refugee Board for a hearing.
        </p>
        <p>
          If you are seeking asylum or making a refugee claim in Canada, click
          the IRCC link below and follow the guidelines carefully. You may want
          to find a legal representative to help you with the process. Please
          also refer to the section{" "}
          <Link to="/claimants/resources/">
            Resources for Refugee Claimants
          </Link>{" "}
          for more information.
        </p>
        <a
          href="https://www.canada.ca/en/immigration-refugees-citizenship/services/refugees/claim-protection-inside-canada/apply.html"
          target="_blank"
          rel="noreferrer"
        >
          https://www.canada.ca/en/immigration-refugees-citizenship/services/refugees/claim-protection-inside-canada/apply.html
        </a>
      </section>
    </Layout>
  )
}

export default Claim
